//
//
//
//
//
//
export default {
  name: "LogoutButton",
  methods: {
    logout: function logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
      this.$router.push({
        path: "/"
      });
    }
  }
};