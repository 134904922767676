//
//
//
//
//
//
export default {
  name: "LoginButton",
  methods: {
    login: function login() {
      this.$auth.loginWithRedirect();
    }
  }
};