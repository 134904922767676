//
//
//
//
//
//
//
import LoginButton from "@/components/LoginButton";
import LogoutButton from "@/components/LogoutButton";
export default {
  name: "AuthenticationButton",
  components: {
    LogoutButton: LogoutButton,
    LoginButton: LoginButton
  }
};